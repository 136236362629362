<template lang="pug">
.mt-2.mr-2.ml-2
  .d-flex(style="justify-content: space-between")
    .d-flex
      v-btn(color="primary", icon, small, @click="() => $router.back()")
        v-icon(center) mdi mdi-arrow-left

      .mt-2(style="font-size: 24px; font-weight: 500") {{ itemConsulta.id + " - " + itemConsulta.nome }}

    .ma-0.pa-0.justify-end.d-flex(style="background: #fff")
      div(style="width: 220px; margin-top: 14px")
        b Selecionados:
        span {{ logsSeleccionados }} Cliente(s)
      div(style="width: 170px; margin-top: 14px")
        b Sucesso:
        span {{ logsSucesso }} Cliente(s)
      .ml-1.mr-1
        v-btn.ml-auto(color="primary", icon, small, @click="dialogLogs = true")
          v-icon mdi mdi-math-log

  v-divider
  v-tabs(v-model="activeTab", color="primary")
    v-tab(key="1") Consulta
    v-tab(key="2") DashBoard

    v-tab-item(key="1")
      .mt-2
        v-row
          v-col(cols="4")
            v-select.mt-1(
              v-model="configSelected",
              label="Cenários",
              placeholder="Selecione ou crie um cenários",
              persistent-placeholder,
              :items="configs",
              item-text="nome",
              item-value="id",
              no-data-text="Nenhum cenário cadastrado",
              return-object,
              outlined,
              dense,
              hide-details,
              @change="selectCenario"
            )
              template(v-slot:prepend-item)
                v-list-item(
                  dense,
                  ripple,
                  @mousedown.prevent,
                  @click="newCenario"
                )
                  v-list-item-action
                    v-icon mdi mdi-plus
                  v-list-item-content
                    v-list-item-title Novo Cenário
                v-divider 

          v-col(cols="3")
            v-row.mt-0
              v-btn.ml-0(
                :disabled="configSelected.id === 0",
                color="primary",
                outlined,
                @click="cloneMode = !cloneMode; dialogCadastroCenarios = true"
              )
                v-icon(left) mdi mdi-text-box-multiple-outline
                span Clonar

              v-menu(offset-y)
                template(v-slot:activator="{ on, attrs }")
                  v-btn.ml-2(
                    color="primary",
                    outlined,
                    v-bind="attrs",
                    v-on="on",
                    :disabled="!dataset.length"
                  )
                    v-icon(left) mdi-file-move-outline
                    | Exportar
                    v-icon(right) mdi-menu-down
                v-list(dense)
                  v-list-item.primary--text(@click="exportarParaCSV")
                    v-list-item-icon
                      v-icon(color="primary") mdi-file-table-outline
                    v-list-item-title.primary--text Exportar dados
                  v-list-item.primary--text(@click="exportarParaExcel")
                    v-list-item-icon
                      v-icon(color="primary") mdi-file-excel-outline
                    v-list-item-title.primary--text Exportar cenário

          v-col.text-right(cols="2")

          v-col.text-right(cols="3")
            v-btn.ml-2(
              :disabled="configSelected.id === 0",
              color="primary",
              outlined,
              @click="dialogCadastroCenarios = true"
            )
              v-icon(left) mdi mdi-pencil-outline
              span Atualizar

            v-btn.ml-2(
              :disabled="configSelected.id === 0",
              color="error",
              outlined,
              @click="dialogExcluir = true"
            )
              v-icon(left) mdi mdi-delete-outline
              span Deletar

        .ma-2(style="font-weight: 400") {{ configSelected.descricao }}

        .d-flex.mt-2.justify-center.align-center(
          v-if="error502",
          style="height: calc(100vh - 405px)"
        )
          v-alert(
            outlined,
            type="warning",
            prominent,
            border="left",
            width="600px"
          )
            | Esta consulta possui um grande volume de dados, recomendamos exportar os dados via MongoDB e consultar no Excel.

        .d-flex.mt-2.justify-center.align-center(
          v-else-if="dataset.length === 0",
          style="height: calc(100vh - 405px)"
        ) Nenhum resultado encontrado nesta consulta

        PivotTable(
          v-else,
          v-model="configRetorno",
          :dataset="dataset",
          :rows="configSelected.configuracao.rows",
          :cols="configSelected.configuracao.cols",
          :vals="configSelected.configuracao.vals",
          :aggregator-name="configSelected.configuracao.aggregatorName",
          :renderer-name="configSelected.configuracao.rendererName",
          :rowOrder="configSelected.configuracao.rowOrder",
          :colOrder="configSelected.configuracao.colOrder",
          :value-filter="configSelected.configuracao.valueFilter",
          style="height: calc(100vh - 295px); overflow-y: auto; overflow-x: auto"
        )

    v-tab-item.mt-2(key="2")
      v-card.ma-2(v-for="(row, i) in configs", :key="i", elevation="3")
        v-card-title.pt-2(style="display: block; text-align: left")
          .d-flex
            div {{ row.nome }}
            div
              v-icon.pt-0.mt-0(
                color="primary",
                right,
                size="22px",
                @click="editCenario(row)"
              ) mdi mdi-pencil-outline

          div(style="font-size: 15px; color: #666") {{ row.descricao }}

        v-card-text.pa-4.pt-0(style)
          PivotTable(
            :manipulate="false",
            :dataset="dataset",
            :rows="row.configuracao.rows",
            :cols="row.configuracao.cols",
            :vals="row.configuracao.vals",
            :aggregator-name="row.configuracao.aggregatorName",
            :renderer-name="row.configuracao.rendererName",
            :rowOrder="row.configuracao.rowOrder",
            :colOrder="row.configuracao.colOrder",
            :value-filter="row.configuracao.valueFilter"
          )

  //- Dialog com titulo e descrição para gravar o cenário
  v-dialog(
    v-model="dialogCadastroCenarios",
    v-if="dialogCadastroCenarios",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 {{ cloneMode ? "Clonar" : configSelected.id === 0 ? "Incluir" : "Atualizar" }} Cenário
      v-card-text
        v-text-field.mt-1(
          label="Nome do Cenário",
          v-model="configSelected.nome",
          outlined,
          maxlength="80",
          counter,
          dense,
          required,
          :rules="rules"
        )
        v-textarea.mt-2(
          label="Descrição do Cenário",
          v-model="configSelected.descricao",
          outlined,
          maxlength="200",
          counter,
          dense,
          required,
          :rules="rules"
        )
      v-card-actions.pr-0.pt-0.mt-0
        v-spacer
        v-btn(color="primary", outlined, @click="cancelaCadastroCenarios()") Cancelar
        v-btn(color="primary", outlined, @click="gravarCenario()")
          span {{ cloneMode ? "Clonar" : configSelected.id === 0 ? "Salvar" : "Atualizar" }}

  //- Dialog para exclusão de cenário
  v-dialog(
    v-model="dialogExcluir",
    v-if="dialogExcluir",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 Deletar Cenário
      v-card-text
        h5 Tem certeza que deseja deletar o cenário - {{ configSelected.nome }}?
      v-card-actions.pr-0.pt-0.mt-0
        v-spacer
        v-btn(color="primary", outlined, @click="dialogExcluir = false") Cancelar
        v-btn(color="error", outlined, @click="deletarCenario") Deletar

  v-dialog(v-model="dialogLogs")
    v-card(min-height="600")
      v-card-title
        h3 Logs de Execução
        v-btn(
          icon,
          @click="dialogLogs = false",
          style="position: absolute; right: 0; top: 0"
        )
          v-icon mdi mdi-close
      v-card-text
        v-row
          v-col(cols="12")
            DataFilter(
              :filterProps="filtersClientes",
              :items="datasetClientesLogs",
              :paginate="false"
            )
              template(v-slot:default="{ items }")
                v-data-table.ma-2.mt-0.pa-0.clientesLogsTable(
                  :headers="clientesLogsHeaders",
                  :items="items",
                  dense,
                  disable-pagination,
                  hide-default-footer,
                  :loading="clientesisLoading",
                  :single-expand="true",
                  show-expand,
                  style="border: 1px solid #efefef"
                )
                  template(v-slot:item.statusserver="{ item }")
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on, attrs }")
                        v-chip(
                          v-on="on",
                          v-bind="attrs",
                          :color="item.statusserver ? 'success' : 'error'",
                          small
                        )
                      span {{ item.statusserver ? "Conectado" : "Desconectado" }}

                  template(v-slot:expanded-item="{ item, isExpanded }")
                    td.pa-2.pl-8.pr-0(
                      colspan="123",
                      style="background: #efefef"
                    )
                      v-data-table.clientLogsTable(
                        :headers="clientesLogsInfoHeaders",
                        :items="item.logs",
                        :footer-props="footerProps",
                        dense,
                        style="border: 1px solid #efefef"
                      )
                        template(v-slot:item.datahora="{ item }")
                          span {{ new Date(item.datahora).toLocaleString() }}
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";
import { state } from "@/socket";

import PivotTable from "@/components/PivotTable.vue";
import ExcellentExport from "excellentexport";

export default {
  name: "view_resultado",
  components: {
    PivotTable,
  },
  data() {
    return {
      initConfig: {
        rowOrder: "key_a_to_z",
        colOrder: "key_a_to_z",
        valueFilter: {},
        rows: [],
        cols: [],
        rendererName: "Tabela",
        aggregatorName: "Soma",
        aggregators: {},
        vals: [],
      },
      activeTab: 0,
      itemConsulta: {},
      dataset: [],
      cloneMode: false,
      dialogCadastroCenarios: false,
      dialogExcluir: false,
      nomeForFind: "",
      error502: false,
      configRetorno: {},
      configSelected: {
        id: 0,
        nome: "",
        descricao: "",
        datahun_id: 0,
        configuracao: {
          rowOrder: "key_a_to_z",
          colOrder: "key_a_to_z",
          valueFilter: {},
          rows: [],
          cols: [],
          rendererName: "Tabela",
          aggregatorName: "Soma",
          aggregators: {},
          vals: [],
        },
      },
      configs: [],
      rules: [(v) => !!v || "Campo obrigatório"],
      dialogLogs: false,
      filtersClientes: { includeSearch: true, items: [] },
      datasetClientesLogs: [],
      footerProps: {
        itemsPerPageText: "Itens por página",
        itemsPerPageOptions: [15, 100, { text: "Todos", value: -1 }],
      },
      logsSeleccionados: " - ",
      logsSucesso: " - ",
      clientesLogsHeaders: [
        {
          text: "ID",
          value: "id",
          width: "70",
          align: "start",
          sortable: true,
          class: "pl-2 pr-2",
          cellClass: "pl-2 pr-2",
        },
        {
          text: "Cliente",
          value: "nomebaseself",
          align: "start",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Hash",
          value: "hash",
          width: "340",
          align: "start",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Status CS Server",
          value: "statusserver",
          width: "110",
          align: "center",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "Última Data/Hora",
          value: "datahora",
          width: "200",
          sortable: true,
          class: "pl-2 pr-2",
          cellClass: "pl-2 pr-2",
        },
        {
          text: "Última Mensagem",
          value: "mensagem",
          align: "start",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          width: "60",
          sortable: true,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
        {
          text: "",
          value: "data-table-expand",
          align: "center",
          width: "60",
          sortable: false,
          class: "pl-0 pr-0",
          cellClass: "pl-0 pr-0",
        },
      ],
      clientesLogsInfoHeaders: [
        { text: "Data/Hora", value: "datahora", width: "240", sortable: false },
        { text: "Tipo", value: "tipo", width: "138", sortable: false },
        { text: "Mensagem", value: "mensagem", sortable: false },
      ],
    };
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    permissoesAcesso() {
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      const role = roles.filter((role) => role.projectid == 257);

      return role.length !== 0;
    },

    cancelaCadastroCenarios() {
      this.dialogCadastroCenarios = false;
      this.cloneMode = false;

      this.getCenarios();
    },

    async getData() {
      this.showLoader();
      let timeout;

      try {
        // Definir o timer para 30 segundos
        timeout = setTimeout(() => {
          this.$toast.warning("A consulta está demorando mais que o previsto.");
        }, 30000);

        const { status, data } = await csapi.get(
          `/v1/datahub/${this.$route.params.id}/resultado`
        );

        if (!status.toString().startsWith("2")) {
          throw new Error("Problema ao carregar os dados, tentar novamente");
        }

        let dataMap = data.map((item) => {
          const {
            _id,
            datahub_id,
            __v,
            processed_at,
            createdAt,
            updatedAt,
            ...rest
          } = item;
          return { ...rest, criado_em: new Date(createdAt).toLocaleString() };
        });

        // Formatar números baseado no modelo
        if (
          this.itemConsulta.modelo.fields &&
          Array.isArray(this.itemConsulta.modelo.fields)
        ) {
          // Criar um mapa de campos -> tipos para consulta rápida
          const tiposCampos = {};
          this.itemConsulta.modelo.fields.forEach((campo) => {
            tiposCampos[campo.name] = campo.type;
          });

          // Formatar os dados conforme os tipos definidos no modelo
          dataMap = dataMap.map((item) => {
            const itemFormatado = { ...item };

            Object.keys(itemFormatado).forEach((key) => {
              // Verificar se o campo existe no modelo e se tem valor
              if (
                tiposCampos[key] &&
                itemFormatado[key] !== null &&
                itemFormatado[key] !== undefined
              ) {
                // Formatar como float (pt-BR)
                if (tiposCampos[key] === "float") {
                  if (typeof itemFormatado[key] === "number") {
                    itemFormatado[key] = new Intl.NumberFormat("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(itemFormatado[key]);
                  }
                }
                // Formatar como inteiro
                else if (
                  tiposCampos[key] === "integer" ||
                  tiposCampos[key] === "int"
                ) {
                  if (typeof itemFormatado[key] === "number") {
                    itemFormatado[key] = new Intl.NumberFormat("pt-BR", {
                      maximumFractionDigits: 0,
                    }).format(itemFormatado[key]);
                  }
                }
              }
            });

            return itemFormatado;
          });
        }

        // Atualizar o dataset com os dados formatados
        this.dataset = dataMap;
      } catch (e) {
        if (e.response && e.response.status === 413) {
          this.error502 = true;
        }

        if (e.response && e.response.status === 404) {
          const errorMessage =
            e.response.status === 404
              ? "Consulta não está disponível"
              : "Problema inesperado ao carregar os dados, consulte o suporte";
          this.$toast.error(errorMessage);
          this.$router.back();
        } else {
          this.$toast.error("Erro ao carregar dados");
          console.error(e);
        }
      } finally {
        clearTimeout(timeout);
        this.hideLoader();
      }
    },

    async getConsultaSelecionada() {
      try {
        const link = `/v1/datahub/${this.$route.params.id}`;
        const res = await csapi.get(link);

        if (res.status.toString().startsWith("2")) {
          this.itemConsulta = res.data;
        }
      } catch (e) {
        console.error("e", e);
      }
    },

    async getCenarios() {
      try {
        const link = `/v1/datahub/${this.$route.params.id}/cenarios`;
        let { status, data } = await csapi.get(link);

        if (!status.toString().startsWith("2")) {
          throw new Error("Problema ao carregar os cenários, tentar novamente");
        }

        if (data.length === 0) {
          await this.gravarCenario(true);
          const res2 = await csapi.get(link);
          data = res2.data;
        }

        this.configs = data;
        this.configSelected =
          this.configs.find((item) => item.nome === this.nomeForFind) ||
          this.configs[0];
        this.nomeForFind = "";
      } catch (e) {
        this.$toast.error(
          "Problem ao carregar os cenários, consulte o suporte"
        );
      }
    },

    editCenario(item) {
      this.configSelected = item;
      this.activeTab = 0;
    },

    async gravarCenario(notScenario = false) {
      if (notScenario) {
        this.configSelected.nome = "Cenário padrão";
        this.configSelected.descricao =
          "Para começar a montar o seu cenário, arraste os campos para as áreas de linhas e colunas. Em seguida selecione o tipo de visualização (Tabelas ou Gráficos) e a forma de agregação (Soma, Média, Contagem, etc). Por fim, clique em atualizar para gravar essa nova configuração.";
      }

      let res = null;
      let localCenario = JSON.parse(JSON.stringify(this.configSelected));
      localCenario.configuracao = this.configRetorno;

      const link =
        this.configSelected.id === 0 || this.cloneMode
          ? `/v1/datahub/${this.$route.params.id}/cenarios`
          : `/v1/datahub/${this.$route.params.id}/cenarios/${this.configSelected.id}`;

      try {
        if (this.cloneMode) localCenario.id = 0;

        res =
          this.configSelected.id === 0 || this.cloneMode
            ? await csapi.post(link, localCenario)
            : await csapi.put(link, localCenario);

        if (res.status.toString().startsWith("2")) {
          this.$toast.success(
            this.cloneMode
              ? "Cenário clonado com sucesso"
              : this.configSelected.id === 0
              ? "Cenário gravado com sucesso"
              : "Cenário atualizado com sucesso"
          );
        }

        this.nomeForFind = this.configSelected.nome;
      } catch (e) {
        console.error("e", e);
        this.$toast.error(
          "Problema ao gravar/atualizar o cenário, consulte o suporte"
        );
      } finally {
        this.cloneMode = false;
        this.dialogCadastroCenarios = false;
        this.configSelected.datahun_id = this.$route.params.id;
        this.getCenarios();
      }
    },

    async deletarCenario() {
      this.dialogExcluir = false;
      try {
        const link = `/v1/datahub/${this.$route.params.id}/cenarios/${this.configSelected.id}`;
        const { status } = await csapi.delete(link);

        if (!status.toString().startsWith("2")) {
          throw new Error("Problema ao deletar o cenário, tentar novamente");
        }

        this.$toast.success("Cenário deletado com sucesso");
      } catch (e) {
        this.$toast.error("Problema ao deletar o cenário, consulte o suporte");
      } finally {
        this.getCenarios();
        this.configSelected = {
          id: 0,
          nome: "",
          descricao: "",
          datahun_id: this.$route.params.id,
          configuracao: JSON.parse(JSON.stringify(this.initConfig)),
        };
      }
    },

    newCenario() {
      this.nomeForFind = this.configSelected.nome;

      this.configSelected = {
        id: 0,
        nome: "",
        descricao: "",
        datahun_id: this.$route.params.id,
        configuracao: JSON.parse(JSON.stringify(this.initConfig)),
      };

      this.cloneMode = false;
      this.dialogCadastroCenarios = true;
    },

    selectCenario(cenario) {
      this.configSelected = cenario;
    },

    async getClientesLogs() {
      this.datasetClientesLogs = [];
      this.logsSeleccionados = " - ";
      this.logsSucesso = " - ";
      this.clientesisLoading = true;

      try {
        const result = await csapi.get(
          `/v1/datahub/${this.$route.params.id}ß?type=full`
        );

        if (result.status.toString().startsWith("2") === false) {
          this.$toast.error("Erro ao buscar logs de execução");
          return;
        }

        result.data.clientes.forEach((cliente) => {
          cliente.id = cliente.cliente.id;
          cliente.hash = cliente.cliente.hash;
          cliente.menu = false;
          cliente.nomebaseself =
            cliente.cliente.nomebaseself ?? cliente.cliente.nome;
          cliente.statusserver = state.socketConnections.some(
            (row) => cliente.cliente.hash === row.data.token.hash
          );

          const lastLog = cliente.logs[cliente.logs.length - 1] || {};
          cliente.datahora = lastLog.datahora
            ? new Date(lastLog.datahora).toLocaleString()
            : "";
          cliente.mensagem = lastLog.mensagem || "";

          delete cliente.cliente;
        });

        this.datasetClientesLogs = result.data.clientes;
        this.logsSeleccionados = result.data.clientes.length;
        this.logsSucesso = result.data.clientes.reduce(
          (acc, item) =>
            acc +
            item.logs.reduce(
              (acc, t) => (t.mensagem.search("TEMPO") > -1 ? acc + 1 : acc),
              0
            ),
          0
        );

        this.datasetClientesLogs.forEach((item) => {
          item.logs = item.logs.reverse();
        });
      } catch (error) {
        this.$toast.error("Erro ao buscar logs de execução");
        console.error(error);
      } finally {
        this.clientesisLoading = false;
      }
    },

    exportarParaExcel() {
      try {
        const pivotTable = document.querySelector(".pvtTable");
        if (!pivotTable) {
          this.$toast.error("Tabela não encontrada");
          return;
        }

        const link = document.createElement("a");
        let fileName =
          this.itemConsulta.id +
          " - " +
          this.itemConsulta.nome +
          " - " +
          this.configSelected.nome +
          " - " +
          new Date().toLocaleDateString() +
          ".xlsx";

        // Usar o ExcellentExport diretamente na tabela pivot
        ExcellentExport.excel(link, pivotTable, "Pivot Table");
        link.download = fileName;
        link.click();

        this.$toast.success("Arquivo Excel exportado com sucesso!");
      } catch (error) {
        console.error("Erro ao exportar para Excel:", error);
        this.$toast.error("Erro ao exportar para Excel");
      }
    },

    exportarParaCSV() {
      try {
        if (!this.dataset || this.dataset.length === 0) {
          this.$toast.error("Não há dados para exportar");
          return;
        }

        const headers = Object.keys(this.dataset[0]);

        let csvContent = headers.join(";") + "\n";

        this.dataset.forEach((item) => {
          const row = headers.map((header) => {
            const value = item[header];
            if (value === null || value === undefined) return "";
            if (typeof value === "object") return JSON.stringify(value);
            if (typeof value === "number")
              return value.toString().replace(".", ",");
            return `"${value.toString().replace(/"/g, '""')}"`;
          });
          csvContent += row.join(";") + "\n";
        });

        // Create blob and download
        const blob = new Blob(["\ufeff" + csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        let fileName =
          this.itemConsulta.id +
          " - " +
          this.itemConsulta.nome +
          " - " +
          this.configSelected.nome +
          " - " +
          new Date().toLocaleDateString() +
          ".csv";

        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.$toast.success("Arquivo exportado com sucesso!");
      } catch (error) {
        console.error("Erro ao exportar:", error);
        this.$toast.error("Erro ao exportar os dados");
      }
    },
  },

  computed: {
    socketConnections() {
      this.datasetClientesLogs.forEach((item) => {
        const index = state.socketConnections.findIndex(
          (row) => item.hash === row.data.token.hash
        );
        if (index != -1) {
          item.statusserver = true;
        } else {
          item.statusserver = false;
        }
      });

      return state.socketConnections;
    },
  },

  mounted() {
    this.setTitle("DataHub - Consulta");
    this.isControl(true);

    if (this.permissoesAcesso()) {
      this.getConsultaSelecionada();
      this.getCenarios();
      this.getClientesLogs();
      this.getData();
    } else {
      this.$toast.error("Você não tem permissão para acessar essa página");
      this.$router.back();
    }
  },
};
</script>